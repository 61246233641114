<template>
  <span class="agent" :class="agent">
    <mdicon :name="icon" class="icon" :width="16" /> {{text}}
  </span>
</template>
<script>
export default {
  props: {
    agent: {
      required: true,
    },
  },
  computed: {
    text() {
      switch (this.agent) {
        case 'desktop':
          return 'Ordinateur';
        case 'mobile':
          return 'Mobile';
        case 'bot':
          return 'Robot prewarm.it';
        case 'bot:mobile':
          return 'Robot prewarm.it mobile';
        default:
          return this.agent;
      }
    },
    icon() {
      switch (this.agent) {
        case 'desktop':
          return 'monitor';
        case 'tablet':
          return 'tablet';
        case 'mobile':
          return 'cellphone';
        case 'bot:mobile':
          return 'robotVacuumVariant';
        case 'bot':
          return 'robotHappyOutline';
        default:
          return 'asterisk';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .agent {
    margin: .5em;
  }
  .icon {
    color: #999;
  }
</style>
