<template>
  <div class="view-project-form">
    <form>
      <h3>Projet</h3>
      <div class="block">
        <label>Nom du projet</label>
        <em>Le nom de votre projet</em>
        <input type="text" v-model="project.name" placeholder="Mon site" />
      </div>
      <div class="block">
        <label>Url</label>
        <em>Entrez ici l'url de votre site,
          cependant si cela ne fonctionne pas entrez l'url de votre sitemap</em>
        <input type="url" v-model="project.url"  placeholder="https://" />
      </div>
      <h3>Valeurs par défaut</h3>
      <div class="block">
        <label>Appareils</label>
        <em>Certains sites ont des caches différents pour les accès mobile</em>
        <div class="radio-list">
          <label>
            <input type="checkbox"
              v-model="project.userSettings.agents" value="desktop" />
            <agent agent="desktop" />
          </label>
          <label>
            <input type="checkbox"
              v-model="project.userSettings.agents" value="mobile" />
            <agent agent="mobile" />
          </label>
          <label>
            <input type="checkbox"
              v-model="project.userSettings.agents" value="bot" />
            <agent agent="bot" />
          </label>
          <label>
            <input type="checkbox"
              v-model="project.userSettings.agents" value="bot:mobile" />
            <agent agent="bot:mobile" />
          </label>
        </div>
      </div>

      <div class="block">
        <label>Programmation</label>
        <em>Quand doit-ton lancer le chauffage ?</em>
        <div class="radio-list">
          <label>
            <input type="radio" name="scheduleMode"
              v-model="project.userSettings.scheduleMode"
              value="daily" />
            Tous les jours (recommandé)
            <em>Nous allumons le chauffage entre 6h et 8h du matin
              afin que vos clients soient bien au chauds sur votre site</em>
          </label>
          <label>
            <input type="radio" name="scheduleMode"
              v-model="project.userSettings.scheduleMode"
              value="occasionally" />
              Chauffage d'appoint, tous les 2 jours environ
            <em>Nous allumons le chauffage de temps en temps...
              de façon à ce qu'il ne fasse pas trop froid sur votre site<br>
              Pratique si vous avez un cache long</em>
          </label>
          <label>
            <input type="radio" name="scheduleMode"
              v-model="project.userSettings.scheduleMode"
              value="off" />
            Désactivée
            <em>Vous gérez vous même en lançant manuellement
              depuis la console d'administration ou via l'API !</em>
          </label>
        </div>
      </div>
      <div class="block">
        <label>Courbe de chauffe</label>
        <em>
            Vitesse de montée en température.
            Si votre site répond vite à notre solicitation,
            avec un mode aggressif nous augmenterons beaucoup plus rapidement la vitesse de chauffe.
            <br>Quoiqu'il arrive vous serez protégé par
           notre système de protection <b>SafeWarm</b>, qui va s'assurer que votre site
            continue de répondre correctement pendant la phase de préchauffage<br>
            En mode aggressif cependant vous pourrez constater des lenteurs.
        </em>
        <div class="radio-list">
          <label>
            <input type="radio" name="warmingCurveMode"
              v-model="project.userSettings.warmingCurveMode"
              value="fast" />
            Aggressif
            <em>Vous avez un site avec de bons serveurs ?
              Nous aussi !</em>
          </label>
          <label>
            <input type="radio" name="warmingCurveMode"
              v-model="project.userSettings.warmingCurveMode"
              value="normal"/>
            Normal (recommandé)
            <em>Ni trop rapide, ni trop lent, le bon compromis</em>
          </label>
          <label>
            <input type="radio" name="warmingCurveMode"
              v-model="project.userSettings.warmingCurveMode"
              value="slow" />
            Doux
            <em>Votre site n'est pas d'ordinaire très véloce ?
               un serveur sous dimensionné ? on va en prendre soin ;)</em>
          </label>
        </div>
      </div>
      <div class="block">
        <label>Economiseur de bande passante</label>
        <em>Chez certains hébergeur la bande passante vous est facturée,
          dans le cas de site importants, celà peut entrainer une surfacturation.<br>
          Nous ? on essaye met déjà tout en place pour que ça vous coûte le moins cher possible,
            mais on peut toujours faire mieux !</em>
        <div class="radio-list">
          <label>
            <input type="radio" name="bandwithSaverMode"
              v-model="project.userSettings.bandwithSaverMode" value="off" />
            Inactif
          </label>
          <label>
            <input type="radio" name="bandwithSaverMode"
              v-model="project.userSettings.bandwithSaverMode" value="active" />
            Actif
            <em>En activant cette option, nous allons parfois
              lancer les requetes sur votre site sans en attendre la réponse.<br>
            Cela fonctionne plutôt bien pour des cache au niveau de votre site,
            cependant si vous utiliser un CDN externe,
            nous vous déconseillons d'activer cette option.
            </em>
          </label>

        </div>
      </div>
      <div class="control-bar">
        <button class="primary" @click.prevent="save">Enregister</button>
      </div>
    </form>
  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex';
import Agent from '../components/Agent.vue';

export default {
  components: { Agent },
  computed: {
    ...mapState('projects', [
      'project',
    ]),
  },
  methods: {
    ...mapActions('projects', [
      'saveProject',
    ]),
    async save() {
      await this.saveProject();
      this.$router.push({ name: 'Project', params: { id: this.project.id } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

h2 span {
  @include text-gradient(linear-gradient(135deg,#833ab4, #fd1d1d))
}

.progress {
  display: flex;

  .step::before {
    content: '';
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 3px solid #555;
    background: #eee;
  }
}
</style>
